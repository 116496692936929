import { AxiosError } from "axios";

export const getRequestErrors = (error: AxiosError) => {
    if (error &&
        error.response &&
        error.response.data &&
        error.response.data.errors &&
        error.response.data.errors.length > 0) {
        return error.response.data.errors;       
    }

    return [];
};
