import React from "react";
import { localize } from "src/utils/l10n";
import { Label } from "src/components/Label/Label";
import "./ChartFooterButtons.scss";
import { TooltipHost } from "@fluentui/react";
import Visage2Icon from "../Visage2Icon/Visage2Icon";
import UnstyledButton from "../UnstyledButton/UnstyledButton";

interface IFooterButton {
    text: string;
    icon: string;
    tooltipText?: string;
    onClick?: () => void;
}

interface IProps {
    buttons: IFooterButton[];
}

const ChartFooterButtons = (props: IProps) => {
    const renderContent = (x: IFooterButton) => {
        return (
            <div className="button-content">
                <Visage2Icon icon={x.icon} size="small" color="p-mid-grey-1" />
                <Label color="p-mid-grey-1" size="body-3">{x.text}</Label>
            </div>
        )
    }

    return (
        <div className="ChartFooterButtons">
            {props.buttons.map((x: IFooterButton) => {
                return (
                    <UnstyledButton
                        className="button"
                        onClick={x.onClick}
                        disabled={!x.onClick}
                    >
                        {x.tooltipText && (
                            <TooltipHost
                                className="marketplace-tooltip"
                                content={
                                    <span dangerouslySetInnerHTML={{
                                        __html: x.tooltipText
                                    }} />
                                }
                            >
                                {renderContent(x)}
                            </TooltipHost>
                        )}
                        {!x.tooltipText && renderContent(x)}
                    </UnstyledButton>
                )
            })}
        </div>
    )
}

export default ChartFooterButtons;
