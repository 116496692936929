import React, { useCallback, useEffect, useState } from 'react';
import { localize } from 'src/utils/l10n';
import FormSteps from '../FormSteps/FormSteps';
import { setPopupForm } from 'src/actions/ui';
import { connect, useDispatch } from 'react-redux';
import FormStep from '../FormSteps/FormStep';
import FormSection from '../FormSection/FormSection';
import FormControl from '../FormControl/FormControl';
import { ChoiceGroup, DatePicker, DayOfWeek, DefaultButton, Dropdown, PrimaryButton, SpinButton, Stack, TextField, TimePicker } from '@fluentui/react';
import { validateRequiredTextField } from 'src/utils';
import './ProjectForm.scss';
import api from 'src/api/SpintrApi';
import { AxiosResponse } from 'axios';
import UnstyledButton from '../UnstyledButton/UnstyledButton';
import Visage2Icon from '../Visage2Icon/Visage2Icon';
import Label from '../Label/Label';
import Loader from '../Loader/Loader';
import StringFormPopup from '../StringFormPopup/StringFormPopup';
import ErrorMessagebar from '../Messagebars/ErrorMessagebar';
import { setConfirmPopup } from 'src/actions/popup';
import getDatePickerLanguageStrings from 'src/utils/getDatePickerLanguageStrings';
import formatDatePickerDate from 'src/utils/formatDatePickerDate';
import { fetchProjects } from 'src/actions/project';
import PopupHeader from '../PopupHeader/PopupHeader';
import ProjectStatusSelector from '../ProjectStatusSelector/ProjectStatusSelector';
import { IApplicationState } from 'src/reducer';
import FormTokenizedObjectInput from '../FormTokenizedObjectInput/FormTokenizedObjectInput';
import PhoenixForm from '../PhoenixForm/PhoenixForm';
import onFormClose from 'src/utils/onFormClose';
import AnimatedDots from '../AnimatedDots/AnimatedDots';
import ChartFooterButtons from '../ChartFooterButtons/ChartFooterButtons';
import { SpintrTypes } from 'src/typings';
import VectorForm from './VectorForm';

interface IProps {
    id?: number;
    instanceId?: number;
    chooseInstance?: boolean;
    instancePro?: boolean;
}

export interface IFormStep {
    title: string;
    cancelButtonText: string;
    saveButtonText: string;
}

export interface IProject {
    id: number;
    name: string;
    startDate: Date;
    endDate?: Date;
    purpose: string;
    goal: string;
    isStrategicGoal: boolean;
    strategicGoal?: string;
    strategicGoalChallenges?: string;
    surveyInterval: number;
    surveyIntervalFreeChoiceDays: number;
    baselineDate?: Date,
    questionModelling: number;
    vectors: any;
    status: number;
    instanceId?: number;
    instance?: any;
    isAnonymous: boolean;
    hasEndQuestion: boolean;
    endQuestionText?: string;
    hasENPSQuestion: boolean;
    targetGroups: any[];
}

interface IState {
    steps: IFormStep[];
    step: number;
    item: IProject;
    projectTypes: any[];
    activeVectorIndex: number;
    activeQuestionIndex: number;
    displayVectorForm: boolean;
    isCreatingVector: boolean;
    isLoading: boolean;
    saveError: any[];
    displayQuestionPreview: boolean;
    isNew: boolean;
    pro: boolean;
}

const removeItemAtIndex = (items: any[], index: number) => {
    const result = [...items];

    result.splice(index, 1);

    return result;
}

const ProjectForm = (props: IProps) => {
    const dispatch = useDispatch();

    const isNew = !props.id || props.id === 0;

    const [state, setState] = useState<IState>({
        steps: [{
            title: localize("PROJECT_INFORMATION"),
            cancelButtonText: localize("Avbryt"),
            saveButtonText: localize("NEXT")
        }, {
            title: isNew ? localize("PROJECT_TYPE_AND_VECTORS") : localize("VECTORS"),
            cancelButtonText: localize("PREVIOUS"),
            saveButtonText: localize("NEXT")
        }, {
            title: localize("Installningar"),
            cancelButtonText: localize("PREVIOUS"),
            saveButtonText: isNew ? localize("LAUNCH_PROJECT") : localize("SAVE")
        }],
        step: 0,
        item: {
            id: 0,
            name: "",
            startDate: new Date(),
            purpose: "",
            goal: "",
            isStrategicGoal: false,
            surveyInterval: 0,
            surveyIntervalFreeChoiceDays: 7,
            questionModelling: 0,
            vectors: [],
            status: 0,
            isAnonymous: false,
            hasEndQuestion: false,
            hasENPSQuestion: false,
            targetGroups: []
        },
        projectTypes: [],
        activeVectorIndex: -1,
        activeQuestionIndex: -1,
        displayVectorForm: false,
        isCreatingVector: false,
        isLoading: !isNew,
        saveError: [],
        displayQuestionPreview: false,
        isNew: isNew,
        pro: props.chooseInstance ? false : props.instancePro,
    });

    useEffect(() => {
        api.get("/api/v1/projects/projecttypes?includeQuestions=true&translate=true&take=1000").then((response: AxiosResponse) => {
            setState(s => ({
                ...s,
                projectTypes: response.data.map((item: any) => {
                    return {
                        ...item,
                        vectors: item.vectors.map((vector: any) => {
                            return {
                                ...vector,
                                questionStatus: 3
                            }
                        })
                    }
                })
            }));
        }).catch(() => {});

        if (!state.isNew) {
            api.get("/api/v1/projects/" + props.id, {
                params: {
                    instanceId: props.instanceId,
                },
            }).then((response: AxiosResponse) => {
                setState(s => ({
                    ...s,
                    isLoading: false,
                    item: {
                        ...response.data,
                        startDate: new Date(response.data.startDate),
                        endDate: response.data.endDate ? new Date(response.data.endDate) : undefined,
                        baselineDate: new Date(response.data.baselineDate),
                        vectors: response.data.vectors.map((v: any) => {
                            return {
                                ...v,
                                questionStatus: 3
                            }
                        })
                    }
                }));
            }).catch(() => {});
        }
    }, []);

    useEffect(() => {
        if (!state.item.instanceId) {
            return;
        }

        api.get("/api/v1/instances/" + state.item.instanceId).then((response) => {
            setState(s => ({
                ...s,
                pro: response.data.pro,
            }));
        });
    }, [state.item.instanceId]);

    const validateStep = (step: number) => {
        let isOk = true;

        if (step === 0) {
            if (!state.item.name ||
                (state.pro && (!state.item.goal || !state.item.purpose)) ||
                (state.item.isStrategicGoal && (!state.item.strategicGoal || !state.item.strategicGoalChallenges)) ||
                (props.chooseInstance && !state.item.instance)) {
                setState(s => ({
                    ...s,
                    saveError: ["VALIDATION_FILL_REQUIRED_FIELDS"]
                }));

                isOk = false;
            }
        } else if (step === 1) {
            const errors : string[] = [];

            if (state.pro) {
                if (state.item.vectors.length < 3) {
                    errors.push("VALIDATION_VECTOR_COUNT");
                }

                if (!!state.item.vectors.find((x: any) => x.questions.length < 1)) {
                    errors.push("VALIDATION_QUESTION_COUNT");
                }
            } else {
                if (state.item.vectors.length < 1) {
                    errors.push("VALIDATION_PROJECT_TYPE");
                }
            }
    
            if (errors.length > 0) {
                setState(s => ({
                    ...s,
                    saveError: errors
                }));

                isOk = false;
            }
        }

        return isOk;
    }

    const close = useCallback(() => {
        onFormClose(dispatch, () => {
            dispatch(setPopupForm({
                popupFormKey: "Project",
                isDisplayed: false
            }));
        });
    }, []);

    const save = useCallback(() => {
        setState(s => ({
            ...s,
            isLoading: true
        }));

        const errors : string[] = [];
        
        let fallbackStep = -1;

        if (!state.item.name ||
            !state.item.baselineDate ||
            (state.pro && (!state.item.goal || !state.item.purpose)) ||
            (state.item.isStrategicGoal && (!state.item.strategicGoal || !state.item.strategicGoalChallenges))) {
            errors.push("VALIDATION_FILL_REQUIRED_FIELDS");
            fallbackStep = 0;
        }

        if (state.pro) {
            if (state.item.vectors.length < 3) {
                errors.push("VALIDATION_VECTOR_COUNT");
                fallbackStep = fallbackStep === -1 ? 1 : fallbackStep;
            }

            if (!!state.item.vectors.find((x: any) => x.questions.length < 1)) {
                errors.push("VALIDATION_QUESTION_COUNT");
                fallbackStep = fallbackStep === -1 ? 1 : fallbackStep;
            }
        }

        if (errors.length > 0) {
            setState(s => ({
                ...s,
                isLoading: false,
                saveError: errors,
                step: fallbackStep
            }));

            return;
        }

        setState(s => ({
            ...s,
            isLoading: true
        }));

        api.post("/api/v1/projects", state.item).then((response: AxiosResponse) => {
            dispatch(fetchProjects());

            dispatch(setPopupForm({
                popupFormKey: "Project",
                isDisplayed: false
            }));

            api.post("/api/v1/language/translate").then(() => {}).catch(() => {});
        }).catch(() => {
            setState(s => ({
                ...s,
                isLoading: false,
                saveError: ["TeknisktFel"]
            }));
        })
    }, [state.item]);

    const generateQuestions = useCallback(() => {
        for (let i = 0; i < state.item.vectors.length; i++) {
            const v = state.item.vectors[i];

            if (v.questionStatus > 2) {
                continue;
            }

            const envelope = {
                projectName: state.item.name,
                projectPurpose: state.item.purpose,
                projectGoal: state.item.goal,
                strategicGoal: state.item.isStrategicGoal ?
                    state.item.strategicGoal :
                    "",
                strategicGoalChallenges: state.item.isStrategicGoal ?
                    state.item.strategicGoalChallenges :
                    "",
                vectors: [v.name]
            };

            setState(s => ({
                ...s,
                item: {
                    ...s.item,
                    vectors: s.item.vectors.map((x: any, index: number) => {
                        if (index === i) {
                            return {
                                ...x,
                                questionStatus: 2
                            }
                        }
                        
                        return x;
                    })
                }
            }));

            api.post("/api/v1/projects/generatequestions", envelope).then((response: AxiosResponse) => {
                setState(s => ({
                    ...s,
                    item: {
                        ...s.item,
                        vectors: s.item.vectors.map((x: any, index: number) => {
                            if (index === i) {
                                return {
                                    ...x,
                                    questionStatus: 3,
                                    questions: [
                                        ...x.questions,
                                        ...response.data[0].questions.map((text: string) => {
                                            return {
                                                text
                                            }
                                        })
                                    ]
                                }
                            }
                            
                            return x;
                        })
                    }
                }));
            }).catch(() => {
                setState(s => ({
                    ...s,
                    item: {
                        ...s.item,
                        vectors: s.item.vectors.map((x: any, index: number) => {
                            if (index === i) {
                                return {
                                    ...x,
                                    questionStatus: 0
                                }
                            }
                            
                            return x;
                        })
                    }
                }));
            });
        }
    }, [state.item]);

    const isGeneratingQuestions = !!state.item.vectors.find(x => x.questionStatus === 2);

    const getProjectType = () => {
        for (let pt of state.projectTypes) {
            if (state.item.vectors.map(x => x.id).join(" ") === pt.vectors.map(x => x.id).join(" ")) {
                return pt.id;
            }
        }

        return state.pro ? 0 : undefined;
    }

    if (state.isLoading) {
        return (
            <Loader />
        )
    }

    if (state.displayVectorForm) {
        return (
            <VectorForm
                isNew={state.isNew}
                pro={state.pro}
                isCreatingVector={state.isCreatingVector}
                displayQuestionPreview={state.displayQuestionPreview}
                vector={state.activeVectorIndex > -1 ? state.item.vectors[state.activeVectorIndex] : undefined}
                onSave={(vector) => {
                    if (state.isCreatingVector) {
                        setState((s) => ({
                            ...s,
                            displayVectorForm: false,
                            isCreatingVector: false,
                            activeVectorIndex: -1,
                            item: {
                                ...s.item,
                                vectors: [
                                    ...s.item.vectors,
                                    vector
                                ]
                            }
                        }));
                    } else {
                        setState((s) => ({
                            ...s,
                            displayVectorForm: false,
                            isCreatingVector: false,
                            activeVectorIndex: -1,
                            item: {
                                ...s.item,
                                vectors: s.item.vectors.map((v: any, index: number) => {
                                    if (index === state.activeVectorIndex) {
                                        return vector;
                                    } else {
                                        return v;
                                    }
                                })
                            }
                        }));
                    }
                }}
                onCancel={() => {
                    setState((s) => ({
                        ...s,
                        displayVectorForm: false,
                        isCreatingVector: false,
                        displayQuestionPreview: false,
                        activeVectorIndex: -1
                    }));
                }}
            />
        )
    }

    return (
        <div className="ProjectForm">
            <PhoenixForm data={state.item}>
            <PopupHeader
                text={state.isNew ? localize("START_NEW_PROJECT") : localize("EDIT_PROJECT")}
                renderFooterContent={state.isNew ? undefined : () => {
                    return (
                        <ProjectStatusSelector
                            projectId={state.item.id}
                            project={state.item}
                            onStatusChange={(status: number) => {
                                setState(s => ({
                                    ...s,
                                    item: {
                                        ...s.item,
                                        status
                                    }
                                }));
                            }} />
                    )
                }}
                onClose={() => {
                    close();
                }} />
            {state.saveError.length > 0 && (
                <ErrorMessagebar
                    errorList={state.saveError}
                    onDismiss={() => {
                        setState(s => ({
                            ...s,
                            saveError: []
                        }));
                    }}
                />
            )}
            <FormSteps
                steps={state.steps}
                step={state.step}
                onCancelClick={() => {
                    if (state.step === 0) {
                        close();
                    } else {
                        setState(s => ({
                            ...s,
                            step: s.step - 1
                        }));
                    }
                }}
                onSaveClick={() => {
                    if (state.step === (state.steps.length - 1)) {
                        save();
                    } else {
                        const isOk = validateStep(state.step);

                        if (!isOk) {
                            return;
                        }

                        if (state.step === 1 && !state.item.baselineDate) {
                            setState((s) => ({
                                ...s,
                                item: {
                                    ...s.item,
                                    baselineDate: new Date(),
                                },
                            }));
                        }

                        setState(s => ({
                            ...s,
                            step: s.step + 1,
                            saveError: []
                        }));
                    }
                }}
                onStepChange={(step: number) => {
                    let isForward = step > state.step;

                    if (isForward) {
                        let isOk = validateStep(state.step);

                        if (!isOk) {
                            return;
                        }

                        if (step === 2 && state.step === 0) {
                            isOk = validateStep(1);
                        }

                        if (!isOk) {
                            return;
                        }

                        if (step === 2 && !state.item.baselineDate) {
                            setState((s) => ({
                                ...s,
                                item: {
                                    ...s.item,
                                    baselineDate: new Date(),
                                },
                            }));
                        }
                    }

                    setState(s => ({
                        ...s,
                        step: step
                    }));
                }}>
                <FormStep step={0}>
                    <FormSection>
                        {props.chooseInstance && (
                            <FormTokenizedObjectInput
                                url={"/api/v1/instances"}
                                label={localize("CUSTOMER")}
                                items={
                                    state.item.instance
                                        ? [state.item.instance]
                                        : []
                                }
                                onChange={(instances) => {
                                    setState((s) => ({
                                        ...s,
                                        item: {
                                            ...state.item,
                                            instance: instances[0],
                                            instanceId: instances[0]?.key,
                                        },
                                    }));
                                }}
                                required
                                itemLimit={1}
                            />
                        )}
                        <FormControl>
                            <TextField
                                value={state.item.name}
                                className="textField"
                                label={localize("PROJECT_NAME")}
                                resizable={false}
                                onChange={(ev?: React.FormEvent<HTMLElement | HTMLInputElement>, newValue?: string) => {
                                    setState(s => ({
                                        ...s,
                                        item: {
                                            ...s.item,
                                            name: newValue
                                        }
                                    }));
                                }}
                                required
                                aria-required
                                validateOnFocusOut
                                validateOnLoad={false}
                                onGetErrorMessage={validateRequiredTextField}
                            />
                        </FormControl>
                        <div className="form-row">
                            <FormControl>
                                <DatePicker
                                    label={localize("Startdatum")}
                                    firstDayOfWeek={DayOfWeek.Monday}
                                    strings={getDatePickerLanguageStrings()}
                                    formatDate={formatDatePickerDate}
                                    placeholder={localize("ValjDatum")}
                                    ariaLabel={localize("ValjDatum")}
                                    value={state.item.startDate}
                                    isRequired={true}
                                    onSelectDate={(date: Date) => {
                                        setState(s => ({
                                            ...s,
                                            item: {
                                                ...s.item,
                                                startDate: date
                                            }
                                        }));
                                    }}
                                />
                            </FormControl>
                            <FormControl>
                                <DatePicker
                                    label={localize("Slutdatum")}
                                    firstDayOfWeek={DayOfWeek.Monday}
                                    strings={getDatePickerLanguageStrings()}
                                    formatDate={formatDatePickerDate}
                                    placeholder={localize("ValjDatum")}
                                    ariaLabel={localize("ValjDatum")}
                                    value={state.item.endDate}
                                    isRequired={false}
                                    onSelectDate={(date: Date) => {
                                        setState(s => ({
                                            ...s,
                                            item: {
                                                ...s.item,
                                                endDate: date
                                            }
                                        }));
                                    }}
                                />
                            </FormControl>
                        </div>
                        <FormControl>
                            <TextField
                                value={state.item.purpose}
                                className="textField"
                                label={localize("PROJECT_PURPOSE")}
                                multiline
                                autoAdjustHeight
                                resizable={false}
                                onChange={(ev?: React.FormEvent<HTMLElement | HTMLInputElement>, newValue?: string) => {
                                    setState(s => ({
                                        ...s,
                                        item: {
                                            ...s.item,
                                            purpose: newValue
                                        }
                                    }));
                                }}
                                required={state.pro}
                                aria-required={state.pro}
                                validateOnFocusOut
                                validateOnLoad={false}
                                onGetErrorMessage={validateRequiredTextField}
                            />
                        </FormControl>
                        <FormControl>
                            <TextField
                                value={state.item.goal}
                                className="textField"
                                label={localize("PROJECT_GOAL")}
                                multiline
                                autoAdjustHeight
                                resizable={false}
                                onChange={(ev?: React.FormEvent<HTMLElement | HTMLInputElement>, newValue?: string) => {
                                    setState(s => ({
                                        ...s,
                                        item: {
                                            ...s.item,
                                            goal: newValue
                                        }
                                    }));
                                }}
                                required={state.pro}
                                aria-required={state.pro}
                                validateOnFocusOut
                                validateOnLoad={false}
                                onGetErrorMessage={validateRequiredTextField}
                            />
                        </FormControl>
                        <FormControl label={localize("IS_STRATEGIC_GOAL")}>
                            <ChoiceGroup
                                defaultSelectedKey={state.item.isStrategicGoal ? "0" : "1"}
                                options={[
                                    { key: "0", text: localize("YES") },
                                    { key: "1", text: localize("NO") },
                                ]}
                                onChange={(e, v) => {
                                    setState(s => ({
                                        ...s,
                                        item: {
                                            ...s.item,
                                            isStrategicGoal: v.key === "0"
                                        }
                                    }))
                                }}
                                required={true}
                                aria-required={true}
                            />
                        </FormControl>
                        {state.item.isStrategicGoal && (
                            <div>
                                <FormControl>
                                    <TextField
                                        value={state.item.strategicGoal}
                                        className="textField"
                                        label={localize("STRATEGIC_GOAL")}
                                        multiline
                                        autoAdjustHeight
                                        resizable={false}
                                        onChange={(ev?: React.FormEvent<HTMLElement | HTMLInputElement>, newValue?: string) => {
                                            setState(s => ({
                                                ...s,
                                                item: {
                                                    ...s.item,
                                                    strategicGoal: newValue
                                                }
                                            }));
                                        }}
                                        required
                                        aria-required
                                        validateOnFocusOut
                                        validateOnLoad={false}
                                        onGetErrorMessage={validateRequiredTextField}
                                    />
                                </FormControl>
                                <FormControl>
                                    <TextField
                                        value={state.item.strategicGoalChallenges}
                                        className="textField"
                                        label={localize("STRATEGIC_GOAL_CHALLENGES")}
                                        multiline
                                        autoAdjustHeight
                                        resizable={false}
                                        onChange={(ev?: React.FormEvent<HTMLElement | HTMLInputElement>, newValue?: string) => {
                                            setState(s => ({
                                                ...s,
                                                item: {
                                                    ...s.item,
                                                    strategicGoalChallenges: newValue
                                                }
                                            }));
                                        }}
                                        required
                                        aria-required
                                        validateOnFocusOut
                                        validateOnLoad={false}
                                        onGetErrorMessage={validateRequiredTextField}
                                    />
                                </FormControl>
                            </div>
                        )}
                    </FormSection>
                </FormStep>
                <FormStep step={1}>
                    <FormSection>
                        {state.projectTypes.length > 0 && state.isNew && (
                            <FormControl>
                                <Dropdown
                                    label={localize("PROJECT_TYPE")}
                                    selectedKey={getProjectType()}
                                    disabled={!state.isNew}
                                    required
                                    placeholder={localize("CHOOSE_PROJECT_TYPE")}
                                    onChange={(_e, v) => {
                                        const id = Number(v.key);

                                        if (id === 0) {
                                            setState(s => ({
                                                ...s,
                                                item: {
                                                    ...s.item,
                                                    vectors: []
                                                }
                                            }));

                                            return;
                                        }

                                        const pt = state.projectTypes.find(x => x.id === id);

                                        setState(s => ({
                                            ...s,
                                            item: {
                                                ...s.item,
                                                vectors: pt.vectors
                                            }
                                        }));
                                    }}
                                    options={[
                                        ...state.projectTypes.map((pt: any) => {
                                            return {
                                                key: pt.id,
                                                text: pt.name
                                            }
                                        }),
                                        ...(state.pro
                                            ? [
                                                  {
                                                      key: 0,
                                                      text: localize(
                                                          "CUSTOM"
                                                      ),
                                                  },
                                              ]
                                            : []),
                                    ]}
                                    styles={{ dropdown: { width: 250 } }}
                                />
                            </FormControl>
                        )}
                        <div className="table">
                            {state.item.vectors.length > 0 && (
                                <div className="row header">
                                    <div className="cell">
                                        <Label size="body-3" color="p-mid-grey-1">{localize("VECTOR")}</Label>
                                    </div>
                                    <div className="cell">
                                        <Label size="body-3" color="p-mid-grey-1">{localize("QUESTION_STATUS")}</Label>
                                    </div>
                                </div>
                            )}
                            {state.item.vectors.map((item: any, index: number) => {
                                const canEdit = state.pro && (state.isNew || !item.id);

                                return (
                                    <div className="row" key={index}>
                                        <div className="cell">
                                            <Label size="body-2" title={item.name}>{item.name}</Label>
                                        </div>
                                        <div className="cell">
                                            <Label size="body-2">
                                                <Stack horizontal>
                                                    {!state.pro && item.questionStatus === 1 ?
                                                        `${item.questions.filter((q) => q.text.length > 0).length} ${localize(item.questions.filter((q) => q.text.length > 0).length === 1 ? "QUESTION" : "QUESTIONS").toLowerCase()}` :
                                                            localize("VECTOR_GENERATE_STATUS_" + item.questionStatus)
                                                    }
                                                    {item.questionStatus === 2 && (<AnimatedDots />)}
                                                </Stack>
                                            </Label>
                                        </div>
                                        <div className="actions">
                                            {!canEdit && (
                                                <UnstyledButton
                                                    title={localize("QUESTIONS_PREVIEW")}
                                                    onClick={() => {
                                                        setState(s => ({
                                                            ...s,
                                                            displayVectorForm: true,
                                                            activeVectorIndex: index,
                                                            displayQuestionPreview: true
                                                        }));
                                                    }}>
                                                    <Visage2Icon icon="eye" size="small" />
                                                </UnstyledButton>
                                            )}
                                            {canEdit && (
                                                <UnstyledButton
                                                    title={localize("Redigera")}
                                                    onClick={() => {
                                                        setState(s => ({
                                                            ...s,
                                                            displayVectorForm: true,
                                                            activeVectorIndex: index
                                                        }));
                                                    }}>
                                                    <Visage2Icon icon="edit" size="small" />
                                                </UnstyledButton>
                                            )}
                                            {canEdit && (
                                                <UnstyledButton
                                                    title={localize("TaBort")}
                                                    onClick={() => {
                                                        dispatch(setConfirmPopup({
                                                            isOpen: true,
                                                            title: "",
                                                            message: localize("ArDuSakerPaAttDuVillTaBortDennaPost"),
                                                            onConfirm: async () => {
                                                                setState(s => ({
                                                                    ...s,
                                                                    item: {
                                                                        ...s.item,
                                                                        vectors: removeItemAtIndex(s.item.vectors, index)
                                                                    }
                                                                }));
                                                            },
                                                        }));
                                                    }}>
                                                    <Visage2Icon icon="trash" size="small" />
                                                </UnstyledButton>
                                            )}
                                        </div>
                                    </div>
                                )
                            })}
                            {state.pro && (
                                <div className="table-footer">
                                    <div>
                                        <UnstyledButton
                                            className="add-button"
                                            disabled={state.item.vectors.length >= 6}
                                            onClick={() => {
                                                setState(s => ({
                                                    ...s,
                                                    displayVectorForm: true,
                                                    isCreatingVector: true
                                                }));
                                            }}>
                                            <Visage2Icon icon="add" color="p-green-1" />
                                            <Label
                                                size="body-2"
                                                weight="medium"
                                                color="p-green-1"
                                                fontType="display">{localize("ADD_VECTOR")}</Label>
                                        </UnstyledButton>
                                    </div>
                                    {state.item.vectors.filter(x => x.questionStatus < 3).length > 0 && (
                                        <div>
                                            <Label
                                                size="body-3"
                                                color="p-mid-grey-1"
                                                weight="medium">
                                                {localize("GENERATE_DESCRIPTION")}
                                            </Label>
                                            <div>
                                                <PrimaryButton
                                                    disabled={isGeneratingQuestions}
                                                    onClick={() => {
                                                        generateQuestions();
                                                    }}>
                                                    {localize(isGeneratingQuestions ? "VECTOR_GENERATE_STATUS_2" : "GENERATE_QUESTIONS")}
                                                    {isGeneratingQuestions && <AnimatedDots />}
                                                </PrimaryButton>
                                            </div>
                                            {isGeneratingQuestions && (
                                                <Loader />
                                            )}
                                        </div>
                                    )}
                                </div>
                            )}
                        </div>
                        {state.item.vectors.length > 2 && (
                            <ChartFooterButtons
                                buttons={[{
                                    text: localize("QUESTION_AMOUNT_LABEL"),
                                    icon: "info-circle",
                                    tooltipText: localize("QUESTION_AMOUNT_DESCRIPTION")
                                        .replace("{0}", state.item.vectors.length)
                                        .replace("{1}", (state.item.vectors.length * 2).toString()),
                                }]}
                            />
                        )}
                    </FormSection>
                </FormStep>
                <FormStep step={2}>
                    <FormSection>
                        <FormControl>
                            <FormTokenizedObjectInput
                                items={state.item.targetGroups}
                                types={[SpintrTypes.UberType.User]}
                                label={localize("Malgrupper")}
                                url={"/api/v1/targetgroups"}
                                showAllWhenEmpty
                                onChange={(targetGroups) => {
                                    setState(s => ({
                                        ...s,
                                        item: {
                                            ...s.item,
                                            targetGroups: targetGroups.map((tg) => {
                                                return {
                                                    ...tg,
                                                    id: tg.id ? tg.id : tg.key
                                                }
                                            })
                                        }
                                    }));
                                }}
                            />
                        </FormControl>
                        <FormControl label={localize("SURVEY_INTERVAL")}>
                            <ChoiceGroup
                                defaultSelectedKey={state.item.surveyInterval.toString()}
                                options={[
                                    { key: "0", text: localize("EVERY_MONTH") },
                                    { key: "1", text: "2 " + localize("MONTHS") },
                                    { key: "2", text: "3 " + localize("MONTHS") },
                                    { key: "3", text: localize("DAYS") },
                                ]}
                                onChange={(e, v) => {
                                    setState(s => ({
                                        ...s,
                                        item: {
                                            ...s.item,
                                            surveyInterval: Number(v.key)
                                        }
                                    }))
                                }}
                                required={true}
                                aria-required={true}
                            />
                        </FormControl>
                        {state.item.surveyInterval === 3 && (
                            <FormControl>
                                <SpinButton
                                    className="spinButton"
                                    defaultValue={state.item.surveyIntervalFreeChoiceDays.toString()}
                                    min={1}
                                    step={1}
                                    onChange={(event: React.SyntheticEvent<HTMLElement>, newValue?: string) => {
                                        setState(s => ({
                                            ...s,
                                            item: {
                                                ...s.item,
                                                surveyIntervalFreeChoiceDays: Number(newValue)
                                            }
                                        }))
                                    }}
                                />
                            </FormControl>
                        )}
                        <FormControl>
                            <div className="form-row">
                            <DatePicker
                                label={localize("BASELINE_DATE")}
                                firstDayOfWeek={DayOfWeek.Monday}
                                strings={getDatePickerLanguageStrings()}
                                formatDate={formatDatePickerDate}
                                placeholder={localize("ValjDatum")}
                                ariaLabel={localize("ValjDatum")}
                                value={state.item.baselineDate}
                                isRequired={true}
                                onSelectDate={(date: Date) => {
                                    setState(s => ({
                                        ...s,
                                        item: {
                                            ...s.item,
                                            baselineDate: date
                                        }
                                    }));
                                }}
                            />
                            {/* TimePicker doesn't seem to re-render properly, this should force it to re-render once baselineDate is set */}
                            {state.item.baselineDate &&
                                <TimePicker
                                    label={localize("ValjTid")}
                                    placeholder={localize("ValjTid")}
                                    ariaLabel={localize("ValjTid")}
                                    increments={15}
                                    allowFreeform
                                    dateAnchor={state.item.baselineDate}
                                    value={state.item.baselineDate}
                                    timeRange={{start: 0, end: 24}}
                                    onChange={(ev, time) => {
                                        setState(s => ({
                                            ...s,
                                            item: {
                                                ...s.item,
                                                baselineDate: time,
                                            }
                                        }));
                                    }}
                                />
                            }
                            </div>
                        </FormControl>
                        {state.isNew && (
                            <FormControl label={localize("QUESTION_MODELLING")}>
                                <ChoiceGroup
                                    defaultSelectedKey={state.item.questionModelling.toString()}
                                    options={[
                                        { key: "0", text: localize("STRAIGHT") },
                                        { key: "1", text: localize("PARTLY_RANDOM") },
                                        { key: "2", text: localize("RANDOM") },
                                    ]}
                                    onChange={(e, v) => {
                                        setState(s => ({
                                            ...s,
                                            item: {
                                                ...s.item,
                                                questionModelling: Number(v.key)
                                            }
                                        }))
                                    }}
                                    required={true}
                                    aria-required={true}
                                />
                            </FormControl>
                        )}
                        <FormControl label={localize("ANON_SURVEYS")}>
                            <ChoiceGroup
                                defaultSelectedKey={state.item.isAnonymous ? "1" : "0"}
                                options={[
                                    { key: "0", text: localize("NO") },
                                    { key: "1", text: localize("YES") }
                                ]}
                                onChange={(e, v) => {
                                    setState(s => ({
                                        ...s,
                                        item: {
                                            ...s.item,
                                            isAnonymous: v.key === "1"
                                        }
                                    }))
                                }}
                                required={true}
                                aria-required={true}
                            />
                        </FormControl>
                        <FormControl label={localize("END_QUESTION")}>
                            <ChoiceGroup
                                defaultSelectedKey={state.item.hasEndQuestion ? "1" : "0"}
                                options={[
                                    { key: "0", text: localize("NO") },
                                    { key: "1", text: localize("YES") }
                                ]}
                                onChange={(e, v) => {
                                    setState((s) => ({
                                        ...s,
                                        item: {
                                            ...s.item,
                                            hasEndQuestion: v.key === "1"
                                        }
                                    }))
                                }}
                                required={true}
                                aria-required={true}
                            />
                        </FormControl>
                        {
                            state.item.hasEndQuestion && <FormControl>
                                <TextField
                                    value={state.item.endQuestionText}
                                    onChange={(e, v) => {
                                    setState((s) => ({
                                        ...s,
                                        item: {
                                            ...s.item,
                                            endQuestionText: v,
                                        }
                                    }));
                                }} />
                            </FormControl>
                        }
                        <FormControl label={localize("END_ENPS_QUESTION_LABEL") + " (" + localize("END_ENPS_QUESTION") + ")"}>
                            <ChoiceGroup
                                defaultSelectedKey={state.item.hasENPSQuestion ? "1" : "0"}
                                options={[
                                    { key: "0", text: localize("NO") },
                                    { key: "1", text: localize("YES") }
                                ]}
                                onChange={(e, v) => {
                                    setState((s) => ({
                                        ...s,
                                        item: {
                                            ...s.item,
                                            hasENPSQuestion: v.key === "1"
                                        }
                                    }))
                                }}
                                required={true}
                                aria-required={true}
                            />
                        </FormControl>
                    </FormSection>
                </FormStep>
            </FormSteps>
            </PhoenixForm>
        </div>
    )
}

const mapStateToProps = (state: IApplicationState, props: IProps) => ({
    ...props,
    instancePro: state.instance.currentInstance.pro,
    questionPopupFormState: state.ui.popupForms.find(x => x.popupFormKey === "StringForm")
});

export default connect(mapStateToProps)(ProjectForm);
