import { DefaultButton, PrimaryButton, Stack, TextField } from '@fluentui/react';
import React, { useState } from 'react';
import { validateRequiredTextField } from 'src/utils';
import { localize } from 'src/utils/l10n';
import FormControl from '../FormControl/FormControl';
import FormSection from '../FormSection/FormSection';
import PopupHeader from '../PopupHeader/PopupHeader';
import { setConfirmPopup } from 'src/actions/popup';
import { setPopupForm } from 'src/actions/ui';
import StringFormPopup from '../StringFormPopup/StringFormPopup';
import UnstyledButton from '../UnstyledButton/UnstyledButton';
import Visage2Icon from '../Visage2Icon/Visage2Icon';
import { useDispatch } from 'react-redux';
import Label from '../Label/Label';

interface IProps {
    vector?: any;
    isCreatingVector?: boolean;
    displayQuestionPreview?: boolean;
    onSave: (vector) => void;
    onCancel: () => void;
    isNew: boolean;
    pro: boolean;
}

interface IState {
    vector: any;
    activeQuestionIndex: number;
}

const VectorForm = (props: IProps) => {
    const dispatch = useDispatch();

    const [state, setState] = useState<IState>({
        vector: props.vector || {
            questions: [],
            questionStatus: 1
        },
        activeQuestionIndex: -1
    });

    const removeItemAtIndex = (items: any[], index: number) => {
        const result = [...items];
    
        result.splice(index, 1);
    
        return result;
    }
    
    const openAddQuestionPopup = () => {
        dispatch(setPopupForm({
            popupFormKey: "StringForm",
            id: 0,
            title: localize("ADD_QUESTION"),
            isDisplayed: true
        }));
    }

    const renderQuestions = () => {
        const canEdit = props.pro && (props.isNew || !state.vector.id);

        return (
            <div className="ProjectForm questions">
                <div>
                    <div className="table">
                        <div className="row header">
                            <div className="cell">
                                <Label size="body-3" color="p-mid-grey-1">{localize("QUESTIONS")}</Label>
                            </div>
                        </div>
                        {state.vector.questions.map((item: any, index: number) => {
                            return (
                                <UnstyledButton className="row" disabled={!canEdit} key={index} onClick={() => {
                                    setState(s => ({
                                        ...s,
                                        activeQuestionIndex: index
                                    }));

                                    dispatch(setPopupForm({
                                        popupFormKey: "StringForm",
                                        id: 0,
                                        title: localize("EDIT_QUESTION"),
                                        isDisplayed: true
                                    }));
                                }}>
                                    <div className="cell">
                                        <Label
                                            size="body-2"
                                            color={!item.text ? "p-light-grey-10" : undefined}
                                        >
                                            {item.text || localize("WRITE_A_QUESTION")}
                                        </Label>
                                    </div>
                                    {canEdit && (
                                        <div className="actions">
                                            <UnstyledButton
                                                title={localize("Redigera")}
                                                onClick={() => {
                                                    setState(s => ({
                                                        ...s,
                                                        activeQuestionIndex: index
                                                    }));

                                                    dispatch(setPopupForm({
                                                        popupFormKey: "StringForm",
                                                        id: 0,
                                                        title: localize("EDIT_QUESTION"),
                                                        isDisplayed: true
                                                    }));
                                                }}>
                                                <Visage2Icon icon="edit" size="small" />
                                            </UnstyledButton>
                                            <UnstyledButton
                                                title={localize("TaBort")}
                                                onClick={() => {
                                                    dispatch(setConfirmPopup({
                                                        isOpen: true,
                                                        title: "",
                                                        message: localize("ArDuSakerPaAttDuVillTaBortDennaPost"),
                                                        onConfirm: async () => {
                                                            setState(s => ({
                                                                ...s,
                                                                activeQuestionIndex: -1,
                                                                vector: {
                                                                    ...s.vector,
                                                                    questions: removeItemAtIndex(s.vector.questions, index)
                                                                } 
                                                            }));
                                                        },
                                                    }));
                                                }}>
                                                <Visage2Icon icon="trash" size="small" />
                                            </UnstyledButton>
                                        </div>
                                    )}
                                </UnstyledButton>
                            )
                        })}
                        {state.vector.questions.length === 0 && (
                            <Label size="body-2" color="p-mid-grey-5" className="no-questions">{localize("NO_QUESTIONS_ERROR")}</Label>
                        )}
                        {canEdit && (
                            <div className="table-footer">
                                <UnstyledButton className="add-button" onClick={openAddQuestionPopup}>
                                    <Visage2Icon icon="add" color="p-green-1" />
                                    <Label
                                        size="body-2"
                                        weight="medium"
                                        color="p-green-1"
                                        fontType="display"
                                    >
                                        {localize("ADD_QUESTION")}
                                    </Label>
                                </UnstyledButton>
                            </div>
                        )}
                    </div>
                </div>
            </div>
        )
    }

    return (
        <div>
            <PopupHeader text={props.displayQuestionPreview ? state.vector.name : props.isCreatingVector ? localize("CREATE_VECTOR") : localize("EDIT_VECTOR")} hideCloseButton />
            {!props.displayQuestionPreview && (
                <FormSection>
                    <FormControl>
                        <TextField
                            value={state.vector.name}
                            className="textField"
                            label={localize("Namn")}
                            resizable={false}
                            onChange={(ev?: React.FormEvent<HTMLElement | HTMLInputElement>, newValue?: string) => {
                                setState(s => ({
                                    ...s,
                                    vector: {
                                        ...s.vector,
                                        name: newValue
                                    }
                                }));
                            }}
                            required
                            aria-required
                            validateOnFocusOut
                            validateOnLoad={false}
                            onGetErrorMessage={validateRequiredTextField}
                            autoFocus
                        />
                    </FormControl>
                </FormSection>
            )}
            {renderQuestions()}
            <Stack className="bottom-buttons" horizontal={true} horizontalAlign="end" tokens={{ childrenGap: 20 }}>
                <DefaultButton
                    onClick={() => {
                        props.onCancel();
                    }}
                    text={localize("GO_BACK")} />
                {!props.displayQuestionPreview && (
                    <PrimaryButton
                        onClick={() => {
                            props.onSave(state.vector);
                        }}
                        text={localize("SAVE")} />
                )}
            </Stack>
            <StringFormPopup
                multiline
                onCancelClick={() => {
                    setState(s => ({
                        ...s,
                        activeQuestionIndex: -1
                    }));

                    dispatch(setPopupForm({
                        popupFormKey: "StringForm",
                        isDisplayed: false
                    }));
                }}
                onSaveClick={(value: string) => {
                    if (state.activeQuestionIndex > -1) {
                        setState(s => ({
                            ...s,
                            activeQuestionIndex: -1,
                            vector: {
                                ...s.vector,
                                questions: s.vector.questions.map((q: any, qIndex: number) => {
                                    if (qIndex === state.activeQuestionIndex) {
                                        return {
                                            ...q,
                                            text: value
                                        }
                                    } else {
                                        return q;
                                    }
                                })
                            }
                        }));
                    } else {
                        setState(s => ({
                            ...s,
                            activeQuestionIndex: -1,
                            vector: {
                                ...s.vector,
                                questions: [
                                    ...s.vector.questions,
                                    {
                                        text: value
                                    }
                                ]
                            }
                        }));
                    }

                    dispatch(setPopupForm({
                        popupFormKey: "StringForm",
                        isDisplayed: false
                    }));
                }}
                value={state.activeQuestionIndex === -1 ? "" : state.vector.questions[state.activeQuestionIndex].text}
                title={localize("EDIT_QUESTION")}
                label={localize("QUESTION")} />
        </div>
    )
}

export default VectorForm;