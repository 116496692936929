import React from "react";
import "./ProjectStatusSelector.scss";
import { connect, useDispatch } from "react-redux";
import { IApplicationState } from "src/reducer";
import { localize } from "src/utils/l10n";
import Label from "../Label/Label";
import { PrimaryButton } from "@fluentui/react";
import Visage2Icon from "../Visage2Icon/Visage2Icon";
import api from "src/api/SpintrApi";
import { fetchProjects } from "src/actions/project";
import { getProjectStatusColor } from "src/utils";

interface IProps {
    projectId: number;
    onStatusChange: any;
    project?: any;
}

const ProjectStatusSelector = (props: IProps) => {
    const setStatus = (status: number) => {
        props.onStatusChange(status);
    };

    if (!props.project) {
        return null;
    }

    return (
        <div className="ProjectStatusSelector">
            <div className="status">
                <Label fontType="display" size="body-2">{localize("STATUS") + ": "}</Label>
                <Label fontType="display" size="body-2" color={getProjectStatusColor(props.project.status)}>{localize("PROJECT_STATUS_" + props.project.status)}</Label>
            </div>
            {props.project.status === 0 && (
                <PrimaryButton
                    className="small-button blue"
                    onClick={() => {
                        setStatus(1);
                    }}>
                    <Visage2Icon icon="pause" type="bold" size="extra-small" />
                    {localize("PAUSE")}
                </PrimaryButton>
            )}
            {props.project.status === 0 && (
                <PrimaryButton
                    className="small-button red"
                    onClick={() => {
                        setStatus(2);
                    }}>
                    <Visage2Icon icon="stop" type="bold" size="extra-small" />
                    {localize("STOP")}
                </PrimaryButton>
            )}
            {(props.project.status === 1 || props.project.status === 2) && (
                <PrimaryButton
                    className="small-button green"
                    onClick={() => {
                        setStatus(0);
                    }}>
                    <Visage2Icon icon="play" type="bold" size="extra-small" />
                    {localize("START")}
                </PrimaryButton>
            )}
        </div>
    )
}

const mapStateToProps = (state: IApplicationState, props) => ({
    ...props,
    project: props.project || state.project.projects.find(x => x.id === props.projectId)
});

export default connect(mapStateToProps)(ProjectStatusSelector);

