export const compare = (a, b, columnId, isAscending) => {
    const aVal = a[columnId];
    const bVal = b[columnId];

    if (isAscending) {
        return aVal > bVal ? 1 : -1;
    } else {
        return aVal < bVal ? 1 : -1;
    }
}
