import React, { useState } from "react";
import { localize } from "src/utils/l10n";

import Label from "src/components/Label/Label";
import UnstyledButton from "src/components/UnstyledButton/UnstyledButton";
import moment from "moment";
import Visage2Icon from "../Visage2Icon/Visage2Icon";

interface IProps {
    index: number;
    headline: string;
    answerCount: number;
    isVisible: boolean;
    color?: string;
    setIsVisible: () => void;
}

const RadarSidebarItemManual = (props: IProps) => {
    return (
        <div className="survey-wrapper" key={props.index}>
            <div className="row">
                {props.color && (
                    <div className="survey-dot" style={{
                        backgroundColor: props.color
                    }}></div>
                )}
                <Label size="body-2" color="p-mid-grey-1">
                    {props.headline}
                </Label>
            </div>
            <div className="row">
                <Label size="h3" weight="semi-bold" fontType="display">
                    {props.answerCount + " " + localize("ANSWERS")}
                </Label>
                {/* <div className="percentage-change-container">
                    <Visage2Icon icon="arrow-up-3" color="p-green-1" size="extra-small" />
                    <Label color="p-green-1" size="body-2">78%</Label>
                </div> */}
                <div className="flex-spacer" />
                <UnstyledButton onClick={() => {
                    props.setIsVisible();
                }}>
                    <Visage2Icon icon={props.isVisible ? "eye" : "eye-slash"} color={props.isVisible ? "p-green-1" : "p-mid-grey-1"} size="small" />
                </UnstyledButton>
            </div>
        </div>
    )
}

export default RadarSidebarItemManual;
