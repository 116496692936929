import { Callout } from "@fluentui/react";
import React, { useCallback, useEffect, useState } from "react";
import { localize } from "src/utils/l10n";

import Label from "src/components/Label/Label";
import UnstyledButton from "src/components/UnstyledButton/UnstyledButton";
import "./FilterButton.scss";
import Visage2Icon from "../Visage2Icon/Visage2Icon";
import { AxiosResponse } from "axios";
import api from "src/api/SpintrApi";
import getNotificationText from "src/utils/notificationText";

interface IProps {
    renderContent: any;
    onClearClick: () => void;
    hasFilter: boolean;
}

interface IState {
    showCallout: boolean;
}

const FilterButton = (props: IProps) => {
    const [state, setState] = useState<IState>({
        showCallout: false
    });

    return (
        <div className={"FilterButton" + (state.showCallout ? " visible" : "")}>
            <UnstyledButton
                title={localize("FILTER")}
                onClick={() => {
                    setState(s => ({
                        ...s,
                        showCallout: !s.showCallout
                    }))
                }}
                id="FilterButton-button"
                className="FilterButton-button">
                <Visage2Icon icon="filter" />
                {props.hasFilter && <div className="button-unread-dot"></div>}
            </UnstyledButton>
            {state.showCallout && (
                <Callout
                    className="Phoenix-callout"
                    isBeakVisible={false}
                    role="dialog"
                    gapSpace={0}
                    target={"#FilterButton-button"}
                    onDismiss={() => {
                        setState(s => ({
                            ...s,
                            showCallout: false
                        }))
                    }}
                    setInitialFocus
                >
                    <div className="Phoenix-callout-header">
                        <Label weight="medium" fontType="display">{localize("FILTER")}</Label>
                        {props.hasFilter && (
                            <UnstyledButton onClick={props.onClearClick}>
                                <Label color="p-link-color" size="body-2">{localize("Rensa")}</Label>
                            </UnstyledButton>
                        )}
                    </div>
                    <div className="content">
                        {props.renderContent()}
                    </div>
                </Callout>
            )}
        </div>
    )
}

export default FilterButton;