import React, { Component } from "react";
import { localize } from "src/utils/l10n";
import { Radar } from "react-chartjs-2";
import {
    Chart as ChartJS,
    RadialLinearScale,
    PointElement,
    LineElement,
    Filler,
    Tooltip,
    Legend,
    ChartOptions,
} from "chart.js";
import { DefaultButton, Dropdown, Modal, PrimaryButton, Stack } from "@fluentui/react";
import Label from "src/components/Label/Label";
import "./RadarView.scss";
import { RouteComponentProps, withRouter } from "react-router-dom";
import api from "src/api/SpintrApi";
import moment from "moment";
import Loader from "src/components/Loader/Loader";
import { debounce } from "src/utils";
import { connect } from "react-redux";
import { IApplicationState } from "src/reducer";
import StatusMessageWithIcon from "../StatusMessageWithIcon/StatusMessageWithIcon";
import Page from "../Page/Page";
import Visage2Icon from "../Visage2Icon/Visage2Icon";
import ProjectSelector from "../ProjectSelector/ProjectSelector";
import VectorChartLabel from "./VectorChartLabel";
import { IVectorChartPosition } from "./RadarViewInner";
import CenteredContent from "../CenteredContent/CenteredContent";
import FormPopup from "../FormPopup/FormPopup";
import { IPopupFormState } from "src/reducers/ui";
import { setPopupForm } from "src/actions/ui";
import ProjectForm from "../ProjectForm/ProjectForm";
import RadarSidebarItem from "./RadarSidebarItem";
import Notifications from "../Notifications/Notifications";
import UnstyledButton from "../UnstyledButton/UnstyledButton";
import ChartFooterButtons from "../ChartFooterButtons/ChartFooterButtons";
import ExportDataPopup from "../ExportDataPopup/ExportDataPopup";
import { setActiveVectorId } from "src/actions/project";
import RadarSidebarItemManual from "./RadarSidebarItemManual";
import FilterButton from "../FilterButton/FilterButton";
import FormControl from "../FormControl/FormControl";
import FormSection from "../FormSection/FormSection";
import { AxiosError, AxiosResponse } from "axios";
import { getRequestErrors } from "src/utils/getRequestErrors";

ChartJS.register(RadialLinearScale, PointElement, LineElement, Filler, Tooltip, Legend);

export type TFunc = () => void
const RadarChartOptions = (onLoad: TFunc): ChartOptions<'radar'> => ({
    responsive: true,
    maintainAspectRatio: false,
    aspectRatio: 1,
    scales: {
      r: {
        min: 0,
        max: 100,
        ticks: {
          count: 6,
          display: true,
          color: '#000000',
          font: {
            size: 12,
            family: 'EloquiaDisplay Medium',
          },
        },
        pointLabels: {
          display: true,
          color: 'transparent',
          font: {
            size: 0,
            family: 'EloquiaDisplay Medium',
          },
        },
      },
    },
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        displayColors: false,
        callbacks: {
          title: () => '',
          label: (item) => {
            return item.dataset.label + ": " + item.formattedValue;
          },
        },
      },
    },
    elements: {
      point: {
        radius: 5,
        hoverRadius: 6,
      },
    },
    animation: {
      onComplete: onLoad,
    },
  })

interface IProps extends RouteComponentProps {
    hasCustomHeadlineFont: boolean;
    dispatch?: any;
    projects?: any[];
    activeProjectId?: number;
    popupFormState?: IPopupFormState;
}

interface IState {
    chartHasRendered: boolean;
    dataSets: any[];
    vectors: any[];
    isLoading: boolean;
    displayExportPopup: boolean;
    updateIndex: number;
    data?: any;
    mode: "list" | "diagram";
    targetGroups?: any[];
    targetGroupId?: number;
    activeSurveyIndex: number;
    errors: string[];
}

class RadarView extends Component<IProps, IState> {
    private ref = React.createRef<ChartJS>();
    private containerRef = React.createRef<HTMLDivElement>();

    constructor(props) {
        super(props);

        this.state = {
            chartHasRendered: false,
            isLoading: true,
            vectors: [],
            dataSets: [],
            displayExportPopup: false,
            updateIndex: 0,
            mode: "diagram",
            activeSurveyIndex: -1,
            errors: []
        };
    }

    private stringToColor = (str) => {
        let hash = 0;
        for (let i = 0; i < str.length; i++) {
            hash = str.charCodeAt(i) + ((hash << 5) - hash);
        }
        let color = "#";
        for (let i = 0; i < 3; i++) {
            const value = (hash >> (i * 8)) & 0xff;
            color += ("00" + value.toString(16)).substr(-2);
        }
        return color;
    };

    public componentDidMount() {
        this.fetchSurveyResults();
        this.fetchTargetGroups();
    }

    componentDidUpdate(prevProps: Readonly<IProps>, prevState: Readonly<IState>, snapshot?: any): void {
        if (prevProps.activeProjectId !== this.props.activeProjectId) {
            this.setState({
                isLoading: true,
                chartHasRendered: false,
            }, () => {
                this.fetchSurveyResults();
            });
        }
    }

    private fetchSurveyResults = () => {
        if (!this.props.activeProjectId) {
            this.setState({
                isLoading: false
            });

            return;
        }

        api.get("/api/v1/surveys/radar/" + this.props.activeProjectId, {
            params: {
                targetGroupId: this.state.targetGroupId || undefined
            }
        }).then((response: AxiosResponse) => {
            const data = response.data;

            const allVectors = data.flatMap((survey) => survey.vectors.map((v: any) => {
                return {
                    ...v,
                    name: v.name
                }
            }));

            // get distinct values from allVectors based on id
            const distinctVectors = allVectors.reduce((acc, curr) => {
                if (!acc.find((vector) => vector.id === curr.id)) {
                    acc.push(curr);
                }
                return acc;
            }, []);

            this.setState({
                data,
                dataSets: data.map((survey, i) => ({
                    label: moment(survey.createdDate).format("ll"),
                    backgroundColor: this.stringToColor(survey.externalId) + "46",
                    borderColor: this.stringToColor(survey.externalId) + "80",
                    data: distinctVectors.map(
                        // fixes surveys with different number of vectors
                        (vector) => survey.vectors.find((surveyVector) => surveyVector.id === vector.id)?.value || 0
                    ),
                    pointBackgroundColor: "transparent",
                    pointBorderColor: "transparent",
                    borderWidth: 0,
                    answerCount: survey.answerCount
                })),
                vectors: distinctVectors,
                isLoading: false,
                errors: []
            });
        }).catch((error: AxiosError) => {
            this.setState({
                errors: getRequestErrors(error),
                isLoading: false,
                dataSets: [],
                data: [],
                vectors: []
            });
        });
    };

    renderSidebar = () => {
        const hasData = !this.state.isLoading &&
            this.state.dataSets.length > 0 &&
            this.state.vectors.length > 0;

        if (!hasData) {
            return null;
        }

        if (this.state.mode === "list") {
            return (
                <div className="radar-right surveys-wrapper">
                    <Label weight="medium">{localize("SURVEYS")}</Label>
                    <RadarSidebarItemManual
                        key={-1}
                        index={-1}
                        headline={localize("Alla")}
                        answerCount={this.state.data.reduce((sum, item) => sum += item.answerCount, 0)}
                        isVisible={this.state.activeSurveyIndex === -1}
                        setIsVisible={() => {
                            this.setState({
                                activeSurveyIndex: -1
                            });
                        }}
                    />
                    {this.state.data.map((s: any, index: number) => {
                        return (
                            <RadarSidebarItemManual
                                key={index}
                                index={index}
                                headline={moment(s.createdDate).format("ll")}
                                answerCount={s.answerCount}
                                color={this.stringToColor(s.externalId)}
                                isVisible={this.state.activeSurveyIndex === index}
                                setIsVisible={() => {
                                    this.setState({
                                        activeSurveyIndex: index
                                    });
                                }}
                            />
                        )
                    })}
                </div>
            )
        }

        const chart = this.ref.current;

        if (!chart) {
            return null;
        }

        const items = chart.options.plugins.legend.labels.generateLabels(chart);

        return (
            <div className="radar-right surveys-wrapper">
                <Label weight="medium">{localize("SURVEYS")}</Label>
                {items.map((item, index) => {
                    const survey = this.state.data[index];

                    return (
                        <RadarSidebarItem
                            key={index}
                            chart={chart}
                            item={item}
                            index={index}
                            survey={survey} />
                    )
                })}
            </div>
        )
    }

    private spintrPlugin = {
        id: "spintrPlugin",
        afterUpdate: (chart, args, options) => {
            if (!this.state.chartHasRendered) {
                this.setState({ chartHasRendered: true });
            }

            if (args.mode === "resize") {
                this.debouncedUpdate();
            }
        },
    };

    getLabelPositions = () => {
        const chart = this.ref.current;

        if (!chart) {
            return [];
        }

        const labels: string[] = chart.scales.r.getLabels();

        const _positions: IVectorChartPosition[] = labels.map((label, idx) => {
            const vector = this.state.vectors.find((vector) => vector.name === label);

            return {
                text: label,
                index: idx,
                total: labels.length,
                vectorId: vector?.id || 0,
                chartWidth: chart.width,
                // @ts-ignore
                ...chart.scales.r.getPointLabelPosition(idx),
            }
        });

        return _positions;
    }

    private debouncedUpdate = debounce(() => this.forceUpdate(), 10);

    renderFormPopup() {
        if (!this.props.popupFormState) {
            return null;
        }

        return (
            <FormPopup popupFormKey="Project">
                <ProjectForm id={this.props.popupFormState.id} />
            </FormPopup>
        )
    }

    render() {
        if (this.props.projects.length === 0) {
            return (
                <CenteredContent>
                    <PrimaryButton
                        className="create-button"
                        onClick={() => {
                            this.props.dispatch(setPopupForm({
                                popupFormKey: "Project",
                                id: 0,
                                title: localize("START_NEW_PROJECT"),
                                isDisplayed: true,
                                hideHeader: true
                            }));
                        }}>
                        {/* <Visage2Icon icon="add" /> */}
                        {localize("START_NEW_PROJECT")}
                    </PrimaryButton>
                    {this.renderFormPopup()}
                </CenteredContent>
            )
        }

        return (
            <Page renderHeader={this.renderHeader.bind(this)} noContentStyle fullScreen>
                {this.renderContent()}
                {this.renderFormPopup()}
            </Page>
        )
    }

    renderModeButton(key: "list" | "diagram", text: string, icon: string) {
        return (
            <UnstyledButton
                className={this.state.mode === key ? "active" : ""}
                title={text}
                onClick={() => {
                    this.setState({
                        mode: key
                    });
                    this.fetchSurveyResults();
                }}>
                <Visage2Icon icon={icon} />
            </UnstyledButton>
        )
    }

    getVectorScore(vectorIndex: number) {
        if (this.state.activeSurveyIndex === -1) {
            let result = 0;

            const dataSetsWithAnswers = [...this.state.dataSets].filter(x => x.answerCount > 0);

            for (const ds of dataSetsWithAnswers) {
                result += ds.data[vectorIndex];
            }

            result = Math.round(result / dataSetsWithAnswers.length);

            return result;
        }

        return this.state.dataSets[this.state.activeSurveyIndex].data[vectorIndex];
    }

    private fetchTargetGroups = () => {
        api.get("/api/v1/targetgroups", {params: {take: 9999, includeEmpty: false}}).then((response) => {
            this.setState({
                targetGroups: response.data.items
            });
        });
    }

    renderHeader() {
        return (
            <div className="header-inner">
                <div className="header-left">
                    <ProjectSelector />
                </div>
                <div className="header-right">
                    <FilterButton
                        onClearClick={() => {
                            this.setState({
                                targetGroupId: undefined,
                                chartHasRendered: false
                            }, this.fetchSurveyResults);
                        }}
                        hasFilter={!!this.state.targetGroupId}
                        renderContent={() => {
                            return (
                                <div>
                                    <FormSection>
                                        <FormControl>
                                            <Dropdown
                                                defaultSelectedKey={0}
                                                label={localize("Malgrupp")}
                                                selectedKey={this.state.targetGroupId}
                                                onChange={(e, v) => {
                                                    const id = Number(v.key);

                                                    this.setState({
                                                        targetGroupId: id,
                                                        chartHasRendered: false
                                                    }, this.fetchSurveyResults);
                                                }}
                                                options={[
                                                    {
                                                        key: 0,
                                                        text: localize("Alla")
                                                    },
                                                    ...this.state.targetGroups.map((tg) => (
                                                        {
                                                            key: tg.id,
                                                            text: tg.name
                                                        }
                                                    ))
                                                ]}
                                            />
                                        </FormControl>
                                    </FormSection>
                                </div>
                            )
                        }}
                    />
                    <div className="plan-switcher">
                        {this.renderModeButton("list", localize("BOX_VIEW"), "element-3")}
                        {this.renderModeButton("diagram", localize("DIAGRAM_VIEW"), "graph")}
                    </div>
                    <DefaultButton onClick={() => {
                        this.props.dispatch(setPopupForm({
                            popupFormKey: "Project",
                            id: this.props.activeProjectId,
                            title: localize("EDIT_PROJECT"),
                            isDisplayed: true,
                            hideHeader: true
                        }));
                    }}>
                        {localize("EDIT_PROJECT")}
                    </DefaultButton>
                    <PrimaryButton
                        className="create-button"
                        onClick={() => {
                            this.props.dispatch(setPopupForm({
                                popupFormKey: "Project",
                                id: 0,
                                title: localize("START_NEW_PROJECT"),
                                isDisplayed: true,
                                hideHeader: true
                            }));
                        }}>
                        <Visage2Icon icon="add" />
                        {localize("START_NEW_PROJECT")}
                    </PrimaryButton>
                    <Notifications />
                </div>
            </div>
        )
    }

    renderContent() {
        const hasData = !this.state.isLoading &&
            this.state.dataSets.length > 0 &&
            this.state.vectors.length > 0;

        if (this.state.isLoading) {
            return (
                <Loader />
            );
        }

        const labelPositions = this.getLabelPositions();

        return (
            <div className="radar-view">
                <div className="radar-view-content">
                    {this.state.isLoading && (
                        <Loader />
                    )}
                    {this.state.errors.length === 0 && !hasData && !this.state.isLoading && (
                        <StatusMessageWithIcon text={localize("NO_DATA_AVAILABLE")} icon="home-1" />
                    )}
                    {this.state.errors.length > 0 && !hasData && !this.state.isLoading && (
                        <>
                            {this.state.errors.map((error: string, index: number) => {
                                return (
                                    <StatusMessageWithIcon text={localize(error)} icon="home-1" key={index} />
                                )
                            })}
                        </>
                    )}
                    {this.state.mode === "diagram" && (
                        <div className="radar-view-graph" ref={this.containerRef}>
                            {!!hasData && !this.state.isLoading && (
                                <div className="canvas-wrapper">
                                    <Radar
                                        //@ts-ignore
                                        ref={this.ref}
                                        data={{
                                            labels: this.state.vectors.map((vector) => vector.name),
                                            datasets: this.state.dataSets,
                                        }}
                                        plugins={[this.spintrPlugin]}
                                        options={RadarChartOptions(() => {
                                            //this.onSetChartSize(this)
                                        })}
                                    />
                                    {!!labelPositions && labelPositions.map((p, idx) => (
                                        <VectorChartLabel id={p.vectorId} key={idx} position={p} />
                                    ))}
                                </div>
                            )}
                        </div>
                    )}
                    {this.state.mode === "list" && (
                        <div className="box-view" ref={this.containerRef}>
                            {!!hasData && !this.state.isLoading && (
                                <div className="boxes">
                                    {this.state.vectors.map((vector: any, index: number) => {
                                        return (
                                            <UnstyledButton className="box" key={index} onClick={() => {
                                                this.props.dispatch(setActiveVectorId(vector.id));
                                                this.props.history.push("/vector-details");
                                            }}>
                                                <Label weight="medium">{vector.name}</Label>
                                                <Label weight="bold" size="h1">{this.getVectorScore(index)}/100</Label>
                                            </UnstyledButton>
                                        )
                                    })}
                                </div>
                            )}
                        </div>
                    )}
                    <ChartFooterButtons
                        buttons={[{
                            text: localize("EXPORT_DATA"),
                            icon: "export-1",
                            tooltipText: localize("EXPORT_DATA_INFO"),
                            onClick: () => {
                                this.setState({
                                    displayExportPopup: true
                                });
                            }
                        }, {
                            text: localize("CALCULATION_DESCRIPTION_LABEL"),
                            icon: "info-circle",
                            tooltipText: localize("CALCULATION_DESCRIPTION"),
                        }]}
                    />
                </div>
                {this.renderSidebar()}
                {this.state.displayExportPopup && (
                    <ExportDataPopup
                        projectId={this.props.activeProjectId}
                        onClose={() => {
                            this.setState({
                                displayExportPopup: false
                            });
                        }}
                    />
                )}
            </div>
        );
    }
}

const mapStateToProps = (state: IApplicationState, props) => ({
    ...props,
    hasCustomHeadlineFont: !!state.instance.currentInstance.fontUrl,
    projects: state.project.projects,
    activeProjectId: state.project.activeProjectId,
    popupFormState: state.ui.popupForms.find(x => x.popupFormKey === "Project"),
});

export default withRouter(connect(mapStateToProps)(RadarView));
