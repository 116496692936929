import React, { useCallback, useEffect, useState } from "react";
import "./ExportDataPopup.scss";
import { localize } from "src/utils/l10n";
import Label from "../Label/Label";
import { DefaultButton, Dropdown, Modal, PrimaryButton, Stack } from "@fluentui/react";
import SpintrLoader from "../Loader/Loader";
import PopupHeader from "../PopupHeader/PopupHeader";
import api from "src/api/SpintrApi";
import { AxiosError, AxiosResponse } from "axios";
import { getRequestErrors } from "src/utils/getRequestErrors";
import StatusMessageWithIcon from "../StatusMessageWithIcon/StatusMessageWithIcon";

interface IProps {
    onClose: () => void;
    projectId: number;
    targetGroupId?: number;
}

const ExportDataPopup = (props: IProps) => {
    const [downloadUrl, setDownloadUrl] = useState<string>("");
    const [hasDownloaded, setHasDownloaded] = useState<boolean>(false);
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [targetGroups, setTargetGroups] = useState<any[]>([]);
    const [targetGroupId, setTargetGroupId] = useState<number>(props.targetGroupId || 0);
    const [errors, setErrors] = useState<string[]>([]);

    const exportData = useCallback(() => {
        setIsLoading(true);

        api.post("/api/v1/surveys/export?projectId=" + props.projectId + "&targetGroupId=" + targetGroupId).then((response: AxiosResponse) => {
            setDownloadUrl(response.data);
            setIsLoading(false);
            setErrors([]);
        }).catch((error: AxiosError) => {
            setErrors(getRequestErrors(error));
            setIsLoading(false);
        });
    }, [targetGroupId]);

    useEffect(() => {
        fetchTargetGroups();
    }, []);

    const fetchTargetGroups = () => {
        api.get("/api/v1/targetgroups", {params: {take: 9999, includeEmpty: false}}).then((response) => {
            setTargetGroups(response.data.items);
            setIsLoading(false);
        });
    }

    return (
        <Modal
            className="spintr-modal modalWithPopupHeader"
            isOpen={true}
            allowTouchBodyScroll
            onDismiss={props.onClose}
            containerClassName="ExportDataPopup"
        >
            <PopupHeader
                text={localize("EXPORT_DATA")}
                onClose={props.onClose} />
            {isLoading && (
                <SpintrLoader />
            )}
            {!isLoading && !hasDownloaded && downloadUrl && (
                <div>
                    <Label>{localize("EXPORT_DONE")}</Label>
                </div>
            )}
            {!isLoading && hasDownloaded && downloadUrl && (
                <div>
                    <Label>{localize("DOWNLOAD_DONE")}</Label>
                </div>
            )}
            {!downloadUrl && !isLoading && (
                <Dropdown
                    defaultSelectedKey={0}
                    label={localize("Malgrupp")}
                    selectedKey={targetGroupId}
                    onChange={(e, v) => {
                        const id = Number(v.key);

                        setTargetGroupId(id);
                    }}
                    options={[
                        {
                            key: 0,
                            text: localize("Alla")
                        },
                        ...targetGroups.map((tg) => (
                            {
                                key: tg.id,
                                text: tg.name
                            }
                        ))
                    ]}
                />
            )}
            {errors.length > 0 && (
                <>
                    {errors.map((error: string, index: number) => {
                        return (
                            <StatusMessageWithIcon text={localize(error)} icon="home-1" key={index} />
                        )
                    })}
                </>
            )}
            {!isLoading && !downloadUrl && (
                <Stack horizontal={true} horizontalAlign="end" tokens={{ childrenGap: 20 }} className="form-buttons">
                    <DefaultButton
                        onClick={props.onClose}
                        text={localize("Stang")} />
                    {!hasDownloaded && (
                        <PrimaryButton
                            onClick={exportData}
                            text={localize("EXPORT_DATA")} />
                    )}
                </Stack>
            )}
            {!isLoading && downloadUrl && (
                <Stack horizontal={true} horizontalAlign="end" tokens={{ childrenGap: 20 }} className="form-buttons">
                    <DefaultButton
                        onClick={props.onClose}
                        text={localize("Stang")} />
                    {!hasDownloaded && (
                        <PrimaryButton
                            onClick={() => {
                                window.open(downloadUrl, "_blank");
                                setHasDownloaded(true);
                            }}
                            text={localize("LaddaNed")} />
                    )}
                </Stack>
            )}
        </Modal>
    )
}

export default ExportDataPopup;
